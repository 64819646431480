<template>
  <minimal-bg-layout class="items-center">
    <div class="mx-auto p-6 content-center md:py-6 lg:px-0 md:w-1/2 xl:w-1/2">
      <div class="flex flex-col px-6 py-8 border border-gray-100 shadow-2xl rounded bg-white lg:px-6">
        <div class="w-full flex flex-row mb-8" style="justify-content: space-between;">
          <div
            class="flex flex-row items-center space-x-1 no-underline">
            <img src="@/assets/img/mini-responsive-samii.png" v-if="!businessLogo" alt="" width="50px" class="m-1" />
            <img :src="businessLogo" v-if="businessLogo" alt="" style="width: 100px !important; height: 100px !important;" />
            <span class="font-bold text-gray-600 text-xl font-headline" v-if="businessName">{{ businessName }}</span>
            <span class="font-bold text-gray-600 text-sm font-headline" v-if="message">{{ message }}</span>
          </div>
          <div class="flex flex-col text-right space-x-1 no-underline" v-if="reference">
            <span class="font-bold text-gray-400 text-md font-headline">{{ date }}</span>
            <span class="font-bold text-gray-600 text-md font-headline">{{ reference }}</span>
          </div>
        </div>
        <div class="w-full flex flex-row">
          <div class="w-1/3">
            <div class="w-full flex flex-col mb-8" v-if="status">
              <span class="font-bold text-gray-400 text-sm font-headline">Estatus</span>
              <span class="font-bold text-gray-600 text-md font-headline">{{ status }}</span>
            </div>
            <div class="w-full flex flex-col mb-8" v-if="dueDate">
              <span class="font-bold text-gray-400 text-sm font-headline">Fecha Compromiso</span>
              <span class="font-bold text-gray-600 text-md font-headline">{{ dueDate }}</span>
            </div>
          </div>
          <div v-if="notes.length" class="w-2/3 text-right" style="max-height: 300px; overflow-y: auto;">
            <span class="font-bold text-gray-400 text-sm font-headline pr-4">Notas</span>
            <div v-for="note in notes" :key="note.id">
              <div class="w-full pb-4 pr-4 rounded-md">
                <div class="font-bold" style="text-transform: capitalize;">{{ dateNote(note) }}</div>
                <div>{{ note.note }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </minimal-bg-layout>
</template>
<script>
import OrderService from '@/services/OrderService';
import error from '@/mixins/error';

export default {
  name: 'MyOrderStatusIndex',
  mixins: [error],
  components: {
    MinimalBgLayout: () => import('@/components/layouts/MinimalBgLayout'),
  },
  data() {
    return {
      id: null,
      business: {},
      order: {
        status: {
          name: null,
        },
      },
      message: null,
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.load();
  },
  computed: {
    businessName() {
      return this.business.name;
    },
    businessLogo() {
      if (this.business.logo) return this.business.logo;
      return '@/assets/img/logo_bg.png';
    },
    reference() {
      return this.order.reference;
    },
    date() {
      return this.order.date ? this.$moment(this.order.date).format('MM/DD/YYYY') : null;
    },
    status() {
      return this.order.status.name;
    },
    dueDate() {
      return this.order.dueDate ? this.$moment(this.order.dueDate).format('MM/DD/YYYY') : null;
    },
    notes() {
      return this.order.notes || [];
    },
  },
  methods: {
    dateNote(note) {
      const date = `${(note.updatedAt || note.createdAt).replace('T', ' ').substr(0, 19)}${'+00:00'}`;
      return this.$moment(date).format('dddd, MMMM Do YYYY, h:mm:ss a');
    },
    async load() {
      try {
        const {order, business} = await OrderService.myOrderStatus(this.id);
        this.business = {
          name: business.name,
          logo: `${process.env.VUE_APP_S3_BUCKET}/${business.logo}`,
        };
        this.order = {
          reference: order.reference,
          date: order.createdAt,
          status: order.orderStatus,
          dueDate: order.dueDate,
          notes: order.notes,
        };
      } catch (e) {
        this.message = this.getErrorMessage(e);
      }
    }
  },
}
</script>
